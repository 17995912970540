import pillIcon from '../assets/img/twd/pill.png'
import respiratoryIcon from '../assets/img/twd/respiratory.png'
import vitalIcon from '../assets/img/twd/vitalSigns.png'
import riskIcon from '../assets/img/twd/risk.png'
import physicalsIcon from '../assets/img/twd/physicals.png'
import bloodIcon from '../assets/img/twd/blood.png'
import diseaseIcon from '../assets/img/twd/genetics.png'

const categories = [
	{
		title: 'Vital signs',
		icon: vitalIcon,
		tag: 'observable entity',
		category: 'vital sign',
		description: 'Monitoring of essential bodily functions'
	},
	{
		title: 'Allergies',
		icon: respiratoryIcon,
		tag: 'finding',
		category: 'allergy',
		description: "Patient's allergic reactions to specific substances"
	},
	{
		title: 'Medications',
		icon: pillIcon,
		tag: 'clinical drug',
		category: 'medication',
		description: 'Prescribed drugs and medications'
	},
	{
		title: 'Diseases',
		icon: diseaseIcon,
		tag: 'disorder',
		category: 'disease',
		description: 'Medical conditions and diseases diagnosed'
	},
	{
		title: 'Risky substances',
		icon: riskIcon,
		tag: 'substance',
		category: 'risky substance',
		description: 'Substances that may pose health risks to the patient'
	},
	{
		title: 'Physicals',
		icon: physicalsIcon,
		tag: 'observable entity',
		category: 'physical',
		description: 'Physical examination and measurements'
	},
	{
		title: 'Bloods',
		icon: bloodIcon,
		tag: 'observable entity',
		category: 'blood',
		description: 'Blood-related health metrics and tests'
	}
]

// NOTE: blood test records
const bloods = [
	{
		term: 'Total cholesterol',
		conceptId: '853681000000104',
		tag: 'observable entity',
		category: 'blood',
		profile: 'lipid',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 50
		}
	},
	{
		term: 'LDL cholesterol',
		conceptId: '1022191000000100',
		tag: 'observable entity',
		category: 'blood',
		profile: 'lipid',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 50
		}
	},
	{
		term: 'HDL cholesterol',
		conceptId: '1005681000000107',
		tag: 'observable entity',
		category: 'blood',
		profile: 'lipid',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 50
		}
	},
	{
		term: 'Non-HDL cholesterol',
		conceptId: '1030411000000101',
		tag: 'observable entity',
		category: 'blood',
		profile: 'lipid',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 50
		}
	},
	{
		term: 'Total cholesterol:HDL cholesterol',
		conceptId: '1028551000000102',
		tag: 'observable entity',
		category: 'blood',
		profile: 'lipid',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 50
		}
	},
	{
		term: 'Triglycerides',
		conceptId: '1005691000000109',
		tag: 'observable entity',
		category: 'blood',
		profile: 'lipid',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 50
		}
	},
	{
		term: 'hs-CRP',
		conceptId: '53661000237108',
		tag: 'observable entity',
		category: 'blood',
		profile: 'inflammation',
		unit: {
			unit: 'mg/L',
			min: 0,
			max: 500
		}
	},
	{
		term: 'Ferritin',
		conceptId: '993381000000106',
		tag: 'observable entity',
		category: 'blood',
		profile: 'iron studies',
		unit: {
			unit: 'ng/mL',
			min: 0,
			max: 1000
		}
	},
	{
		term: 'Creatinine',
		conceptId: '1000731000000107',
		tag: 'observable entity',
		category: 'blood',
		profile: 'kidney health',
		unit: {
			unit: 'µmol/L',
			min: 0,
			max: 500
		}
	},
	{
		term: 'Urea',
		conceptId: '1000951000000103',
		tag: 'observable entity',
		category: 'blood',
		profile: 'kidney health',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 100
		}
	},
	{
		term: 'eGFR',
		conceptId: '1107411000000104',
		tag: 'observable entity',
		category: 'blood',
		profile: 'kidney health',
		unit: {
			unit: 'mL/min/1.73m2',
			min: 0,
			max: 120
		}
	},
	{
		term: 'Sodium',
		conceptId: '1000661000000107',
		tag: 'observable entity',
		category: 'blood',
		profile: 'kidney health',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 300
		}
	},
	{
		term: 'Potassium',
		conceptId: '1000651000000109',
		tag: 'observable entity',
		category: 'blood',
		profile: 'kidney health',
		unit: {
			unit: 'mmol/L',
			min: 0,
			max: 20
		}
	},
	{
		term: 'Bilirubin',
		conceptId: '999691000000104',
		tag: 'observable entity',
		category: 'blood',
		profile: 'liver health',
		unit: {
			unit: 'mg/dL',
			min: 0,
			max: 100
		}
	},
	{
		term: 'Alkaline phosphatase',
		conceptId: '1000621000000104',
		tag: 'observable entity',
		category: 'blood',
		profile: 'liver health',
		unit: {
			unit: 'IU/L',
			min: 0,
			max: 1000
		}
	},
	{
		term: 'Alanine transaminase',
		conceptId: '1018251000000107',
		tag: 'observable entity',
		category: 'blood',
		profile: 'liver health',
		unit: {
			unit: 'U/L',
			min: 0,
			max: 1000
		}
	},
	{
		term: 'Gamma-glutamyl transferase',
		conceptId: '1000861000000106',
		tag: 'observable entity',
		category: 'blood',
		profile: 'liver health',
		unit: {
			unit: 'U/L',
			min: 0,
			max: 1000
		}
	},
	{
		term: 'Albumin',
		conceptId: '1000821000000103',
		tag: 'observable entity',
		category: 'blood',
		profile: 'liver health',
		unit: {
			unit: 'g/L',
			min: 0,
			max: 100
		}
	},
	{
		term: 'B12',
		conceptId: '993391000000108',
		tag: 'observable entity',
		category: 'blood',
		profile: 'vitamins',
		unit: {
			unit: 'pg/mL',
			min: 0,
			max: 1000
		}
	},
	{
		term: 'Vitamin D',
		conceptId: '1031181000000107',
		tag: 'observable entity',
		category: 'blood',
		profile: 'vitamins',
		unit: {
			unit: 'nmol/L',
			min: 0,
			max: 1000
		}
	},
	{
		term: 'HbA1C',
		conceptId: '1107481000000106',
		tag: 'observable entity',
		category: 'blood',
		profile: 'diabetes monitoring',
		unit: {
			unit: 'mmol/mol',
			min: 0,
			max: 100
		}
	}
]

// NOTE: physical medical records
const physicals = [
	{
		term: 'Hip circumference',
		conceptId: '284472007',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: 'cm',
			min: 20,
			max: 300
		}
	},
	{
		term: 'Waist circumference',
		conceptId: '276361009',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: 'cm',
			min: 20,
			max: 300
		}
	},
	{
		term: 'Neck circumference',
		conceptId: '420236003',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: 'cm',
			min: 20,
			max: 200
		}
	},
	{
		term: 'Body fat',
		conceptId: '248300009',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: '%',
			min: 0,
			max: 100
		}
	},
	{
		term: 'Body weight',
		conceptId: '27113001',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: 'kg',
			min: 0,
			max: 300
		}
	},
	{
		term: 'QRISK3 cardiovascular disease 10 year risk calculator score',
		conceptId: '1085871000000105',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: '%',
			min: 0,
			max: 100
		}
	}
]

// NOTE: these are physical medical records that can only be set once for adults of, e.g.: one birthday, one height in adults, one biological sex
const staticPhysicals = [
	{
		term: 'Date of birth',
		conceptId: '184099003',
		tag: 'observable entity',
		unit: {
			unit: 'date'
		}
	},
	{
		term: 'Body height',
		conceptId: '1153637007',
		tag: 'observable entity',
		category: 'physical',
		unit: {
			unit: 'cm',
			min: 50,
			max: 280
		}
	},
	{
		term: 'Biological sex',
		conceptId: '429019009',
		tag: 'finding',
		unit: {
			unit: 'sex'
		}
	}
]

// NOTE: fundamental observations records
const vitalSigns = [
	{
		term: 'Respiratory rate',
		conceptId: '86290005',
		tag: 'observable entity',
		category: 'vital sign',
		unit: {
			unit: 'bpm',
			min: 0,
			max: Infinity
		}
	},
	{
		term: 'O2 -Oxygen saturation',
		conceptId: '103228002',
		tag: 'observable entity',
		category: 'vital sign',
		unit: {
			unit: '%',
			min: 0,
			max: 100
		}
	},
	{
		term: 'Diastolic blood pressure',
		conceptId: '271650006',
		tag: 'observable entity',
		category: 'vital sign',
		unit: {
			unit: 'mmHg',
			min: 20,
			max: 200
		}
	},
	{
		term: 'Systolic blood pressure',
		conceptId: '271649006',
		tag: 'observable entity',
		category: 'vital sign',
		unit: {
			unit: 'mmHg',
			min: 50,
			max: 300
		}
	},
	{
		term: 'Resting heart rate',
		conceptId: '444981005',
		tag: 'observable entity',
		category: 'vital sign',
		unit: {
			unit: 'bpm',
			min: 0,
			max: 300
		}
	},
	{
		term: 'Temperature',
		conceptId: '703421000',
		tag: 'observable entity',
		category: 'vital sign',
		unit: {
			unit: 'celsius',
			min: 30,
			max: 50
		}
	}
]

export { bloods, physicals, staticPhysicals, vitalSigns, categories }
