// TODO: remove the references to this and attempt to use skeletons in the components instead of having a global loading on the whole page

import React from 'react'
import ReactLoading from 'react-loading'

interface LoadingProps {
	size?: number
}

const Loading: React.FC<LoadingProps> = ({ size }) => (
	<div
		className="m-auto flex items-center justify-center"
		style={{ width: '100%', height: '100%' }}
	>
		<ReactLoading type={'bubbles'} height={size ?? 100} width={size ?? 100} color={'#FF6600'} />
	</div>
)

export default Loading
